/*
  Chosen
  https://harvesthq.github.io/chosen/
  https://github.com/harvesthq/chosen/blob/master/sass/chosen.scss
*/

/* stylelint-disable */
$chosen-sprite: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='52' height='37'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath fill='%23888' d='M48.5 4.5L52 1l-1-1-3.5 3.5L44 0l-1 1 3.5 3.5L43 8l1 1 3.5-3.5L51 9l1-1z'/%3E%3Cpath fill='%23464646' d='M48.5 15.5L52 12l-1-1-3.5 3.5L44 11l-1 1 3.5 3.5L43 19l1 1 3.5-3.5L51 20l1-1z'/%3E%3Cpath fill='%23888' fill-rule='nonzero' d='M41.1 33.7l2.7 2.7c.4.4 1.2.4 1.6 0 .4-.5.4-1.2 0-1.6l-2.7-2.7c1.1-1.8.9-4.2-.7-5.7a4.7 4.7 0 1 0-.9 7.3zm-4.3-6c1-1 2.8-1 3.9 0 1 1 1 2.8 0 4a3 3 0 0 1-4 0 3 3 0 0 1 0-4z'/%3E%3Cpath fill='%23888' d='M2 7h7l-3.5 5zm25 5h-7l3.5-5z'/%3E%3C/g%3E%3C/svg%3E");
.chosen-container {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  font-size: $font-size-sm;
  user-select: none;
  width: 100% !important;
  z-index: 1010;
  * {
    box-sizing: border-box;
  }
  .chosen-drop {
    position: absolute;
    top: 100%;
    width: 100%;
    border: var(--bs-border-width) solid var(--bs-border-color);
    border-top: 0;
    background: #fff;
    clip: rect(0,0,0,0);
    clip-path: inset(100% 100%);
    box-shadow: var(--bs-box-shadow-sm);
  }
  &.chosen-with-drop .chosen-single {
    border-bottom: 0;
  }
  &.chosen-with-drop .chosen-drop {
    clip: auto;
    clip-path: none;
  }
  a{
    cursor: pointer;
  }
  .search-choice, .chosen-single{
    .group-name{
      margin-right: 4px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-weight: normal;
      color: #999999;
      &:after {
        content: ":";
        padding-left: 2px;
        vertical-align: top;
      }
    }
  }
}
/* Single Chosen */
.chosen-container-single{
  .chosen-single {
    position: relative;
    display: block;
    overflow: hidden;
    padding: 0 0 0 8px;
    height: $input-height;
    border: var(--bs-border-width) solid var(--bs-border-color);
    border-radius: $input-border-radius;
    background-color: $input-bg;
    background: linear-gradient(#fff 20%, #f6f6f6 45%, #f0f0f0 55%, #f4f4f4 100%);
    background-clip: padding-box;
    color: $form-select-color;
    text-decoration: none;
    white-space: nowrap;
    line-height: $input-height;
    z-index: 1;
  }
  .chosen-default {
    color: #999;
  }
  .chosen-single span {
    display: block;
    overflow: hidden;
    margin-right: 26px;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .chosen-single-with-deselect span {
    margin-right: 38px;
  }
  .chosen-single abbr {
    position: absolute;
    top: 6px;
    right: 26px;
    display: block;
    width: 12px;
    height: 12px;
    background: $chosen-sprite -42px 1px no-repeat;
    font-size: 1px;
    &:hover {
      background-position: -42px -10px;
    }
  }
  &.chosen-disabled .chosen-single abbr:hover {
    background-position: -42px -10px;
  }
  .chosen-single div {
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    width: 26px;
    height: 100%;
    b {
      display: block;
      width: 100%;
      height: 100%;
      background-image: $chosen-sprite;
      background-position: 4px 6px;
      background-repeat: no-repeat;
      background-size: 66px;
    }
  }
  .chosen-search {
    padding: 6px;

    input[type="text"] {
      @extend .form-control;
      @extend .form-control-sm;

      background-image: $chosen-sprite;
      background-position: 100% -26px;
      background-repeat: no-repeat;
      background-size: 66px;
    }
  }
  .chosen-drop {
    margin-top: -1px;
    border-radius: 0 0 4px 4px;
    background-clip: padding-box;
  }
  &.chosen-container-single-nosearch .chosen-search {
    position: absolute;
    clip: rect(0,0,0,0);
    clip-path: inset(100% 100%);
  }
}

/* Results */
.chosen-container .chosen-results {
  color: #444;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  margin: 0 4px 4px 0;
  padding: 0 0 0 4px;
  max-height: 240px;
  -webkit-overflow-scrolling: touch;
  li {
    display: none;
    margin: 0;
    padding: 5px 6px;
    list-style: none;
    line-height: 15px;
    word-wrap: break-word;
    -webkit-touch-callout: none;
    &.active-result {
      display: list-item;
      cursor: pointer;
    }
    &.disabled-result {
      display: list-item;
      color: #ccc;
      cursor: default;
    }
    &.highlighted {
      background-color: $primary;
      background-image: $gradient;
      color: #fff;
    }
    &.no-results {
      color: #777;
      display: list-item;
      background: #f4f4f4;
    }
    &.group-result {
      display: list-item;
      font-weight: bold;
      cursor: default;
    }
    &.group-option {
      padding-left: 15px;
    }
    em {
      font-style: normal;
      text-decoration: underline;
    }
  }
}

/* Multi Chosen */
.chosen-container-multi{
  .chosen-choices {
    box-shadow: $input-box-shadow;
    position: relative;
    overflow: hidden;
    margin: 0;
    padding: 0 5px;
    width: 100%;
    height: auto;
    border: $input-border-width solid $input-border-color;
    background-color: $input-bg;
    cursor: text;
  }
  .chosen-choices li {
    float: left;
    list-style: none;
    &.search-field {
      margin: 0;
      padding: 0;
      white-space: nowrap;
      input[type="text"] {
        padding: 0;
        height: $input-height;
        outline: 0;
        border: 0 !important;
        background: transparent !important;
        color: #999;
        font-size: $font-size-sm;
        width: 25px;
      }
    }
    &.search-choice {
      position: relative;
      margin: 4px 5px 0 0;
      padding: 3px 20px 3px 5px;
      border: 1px solid $gray-500;
      max-width: 100%;
      border-radius: 3px;
      background-color: $gray-200;
      background-image: $gradient;
      box-shadow: $box-shadow-sm;
      color: $gray-700;
      line-height: $line-height-sm;
      cursor: default;
      span {
        word-wrap: break-word;
      }
      .search-choice-close {
        position: absolute;
        top: 6px;
        right: 3px;
        display: block;
        width: 12px;
        height: 12px;
        background: $chosen-sprite -42px 1px no-repeat;
        font-size: 1px;
        &:hover {
          background-position: -42px -10px;
        }
      }
    }
    &.search-choice-disabled {
      padding-right: 5px;
      border: 1px solid #ccc;
      background-color: #e4e4e4;
      background-image: linear-gradient(#f4f4f4 20%, #f0f0f0 50%, #e8e8e8 52%, #eee 100%);
      color: #666;
    }
    &.search-choice-focus {
      background: #d4d4d4;
      .search-choice-close {
        background-position: -42px -10px;
      }
    }
  }
  .chosen-results {
    margin: 0;
    padding: 0;
  }
  .chosen-drop .result-selected {
    display: list-item;
    color: #ccc;
    cursor: default;
  }
}
/* Active  */
.chosen-container-active{
  .chosen-single {
    border: $input-border-width solid $input-border-color;
  }
  &.chosen-with-drop{
    .chosen-single {
      background-image: linear-gradient(#eee 20%,#fff 80%);
    }
    .chosen-single div {
      b {
        background-position: -18px 6px;
      }
    }
  }
  .chosen-choices {
    border: $input-border-width solid $input-focus-border-color;
    box-shadow: $input-focus-box-shadow;
    li.search-field input[type="text"] {
      color: #222 !important;
    }
  }
}
/* Disabled Support */
.chosen-disabled {
  opacity: 0.5 !important;
  cursor: default;
  .chosen-single {
    cursor: default;
  }
  .chosen-choices .search-choice .search-choice-close {
    cursor: default;
  }
}
/* Right to Left */
.chosen-rtl {
  text-align: right;
  .chosen-single {
    overflow: visible;
    padding: 0 8px 0 0;
  }
  .chosen-single span {
    margin-right: 0;
    margin-left: 26px;
    direction: rtl;
  }
  .chosen-single-with-deselect span {
    margin-left: 38px;
  }
  .chosen-single div {
    right: auto;
    left: 3px;
  }
  .chosen-single abbr {
    right: auto;
    left: 26px;
  }
  .chosen-choices li {
    float: right;
    &.search-field input[type="text"] {
      direction: rtl;
    }
    &.search-choice {
      margin: 3px 5px 3px 0;
      padding: 3px 5px 3px 19px;
      .search-choice-close {
        right: auto;
        left: 4px;
      }
    }
  }
  &.chosen-container-single .chosen-results {
    margin: 0 0 4px 4px;
    padding: 0 4px 0 0;
  }
  .chosen-results li.group-option {
    padding-right: 15px;
    padding-left: 0;
  }
  &.chosen-container-active.chosen-with-drop .chosen-single div {
    border-right: none;
  }
  .chosen-search input[type="text"] {
    background: $chosen-sprite no-repeat -30px -20px;
    direction: rtl;
  }
  &.chosen-container-single{
    .chosen-single div b {
      background-position: 6px 2px;
    }
    &.chosen-with-drop{
      .chosen-single div b {
        background-position: -12px 2px;
      }
    }
  }
}