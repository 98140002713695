/* 
  Datepicker
*/

/* stylelint-disable */
.ui-datepicker-prev {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='11' height='18'%3E%3Cpath fill='none' stroke='%23666' stroke-linecap='square' d='M10 0L1 9l9 9'/%3E%3C/svg%3E");
  }
  
  .ui-datepicker-next {
    order: 1;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='11' height='18'%3E%3Cpath fill='none' stroke='%23666' stroke-linecap='square' d='M1 0l9 9-9 9'/%3E%3C/svg%3E");
  }
  
  .gform-theme-datepicker:not(.gform-legacy-datepicker) {
    background: #fff;
    border: 1px solid #d0d1d3;
    border-radius: 3px;
    box-shadow: 0 1px 4px #0000001c, 0 0 4px #1219610a;
    color: #607382;
    font-size: 13px;
    font-weight: 500;
    margin-top: 5px;
    padding: 18px 15px;
    width: auto;
  }
  
  .gform-theme-datepicker:not(.gform-legacy-datepicker) table,
  .gform-theme-datepicker:not(.gform-legacy-datepicker) thead,
  .gform-theme-datepicker:not(.gform-legacy-datepicker) tr,
  .gform-theme-datepicker:not(.gform-legacy-datepicker) td,
  .gform-theme-datepicker:not(.gform-legacy-datepicker) th {
    background: none;
    border: 0;
    margin: 0;
  }
  
  .gform-theme-datepicker:not(.gform-legacy-datepicker) td,
  .gform-theme-datepicker:not(.gform-legacy-datepicker) th {
    padding: 5px;
    text-shadow: none;
    text-transform: none;
  }
  
  .gform-theme-datepicker:not(.gform-legacy-datepicker) .ui-datepicker-header {
    border: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin: 0 0 5px;
    padding: 0;
    position: relative;
    width: 100%;
  }
  
  .gform-theme-datepicker:not(.gform-legacy-datepicker) .ui-datepicker-header .ui-datepicker-prev,
  .gform-theme-datepicker:not(.gform-legacy-datepicker) .ui-datepicker-header .ui-datepicker-next {
    background-size: contain;
    width: 11px;
    height: 18px;
    cursor: pointer;
  }
  
  .gform-theme-datepicker:not(.gform-legacy-datepicker) .ui-datepicker-header .ui-datepicker-month,
  .gform-theme-datepicker:not(.gform-legacy-datepicker) .ui-datepicker-header .ui-datepicker-year {
    @extend .form-select;
    @extend .form-select-sm;
  
    text-align: left;
    margin: 0 0.25rem;
  }
  
  .gform-theme-datepicker:not(.gform-legacy-datepicker) .ui-datepicker-header .ui-datepicker-prev:focus,
  .gform-theme-datepicker:not(.gform-legacy-datepicker) .ui-datepicker-header .ui-datepicker-prev:hover,
  .gform-theme-datepicker:not(.gform-legacy-datepicker) .ui-datepicker-header .ui-datepicker-next:focus,
  .gform-theme-datepicker:not(.gform-legacy-datepicker) .ui-datepicker-header .ui-datepicker-next:hover {
    color: #2f4054;
  }
  
  .gform-theme-datepicker:not(.gform-legacy-datepicker) .ui-datepicker-header .ui-datepicker-prev .ui-icon,
  .gform-theme-datepicker:not(.gform-legacy-datepicker) .ui-datepicker-header .ui-datepicker-next .ui-icon {
    display: none;
  }
  
  .gform-theme-datepicker:not(.gform-legacy-datepicker) .ui-datepicker-header .ui-datepicker-prev::before,
  .gform-theme-datepicker:not(.gform-legacy-datepicker) .ui-datepicker-header .ui-datepicker-next::before {
    border: 0;
    height: auto;
    position: static;
    transform: none;
    width: auto;
  }
  
  .gform-theme-datepicker:not(.gform-legacy-datepicker) .ui-datepicker-header .ui-datepicker-title {
    display: flex;
  }
  
  .gform-theme-datepicker:not(.gform-legacy-datepicker) .ui-datepicker-header .ui-datepicker-prev {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='11' height='18'%3E%3Cpath fill='none' stroke='%23666' stroke-linecap='square' d='M10 0L1 9l9 9'/%3E%3C/svg%3E");
    left: 0;
  }
  
  .gform-theme-datepicker:not(.gform-legacy-datepicker) .ui-datepicker-header .ui-datepicker-next {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='11' height='18'%3E%3Cpath fill='none' stroke='%23666' stroke-linecap='square' d='M1 0l9 9-9 9'/%3E%3C/svg%3E");
    right: 0;
  }
  
  .gform-theme-datepicker:not(.gform-legacy-datepicker) .ui-datepicker-calendar span,
  .gform-theme-datepicker:not(.gform-legacy-datepicker) .ui-datepicker-calendar a {
    font-weight: 400;
  }
  
  .gform-theme-datepicker:not(.gform-legacy-datepicker) .ui-datepicker-calendar th span {
    align-items: center;
    color: #2f4054;
    display: flex;
    font-size: 13px;
    font-weight: 500;
    height: 40px;
    justify-content: center;
    width: 40px;
  }
  
  .gform-theme-datepicker:not(.gform-legacy-datepicker) .ui-datepicker-calendar td {
    font-size: 13px;
    height: 50px;
    width: 50px;
  }
  
  .gform-theme-datepicker:not(.gform-legacy-datepicker) .ui-datepicker-calendar .ui-state-default {
    align-items: center;
    background: none;
    border: 1px solid transparent;
    border-radius: 100%;
    box-shadow: none;
    color: #2f4054;
    display: flex;
    height: 40px;
    justify-content: center;
    transition: color 300ms ease-in-out, background-color 300ms ease-in-out,
      border-color 300ms ease-in-out;
    text-decoration: none;
    width: 40px;
  }
  
  .gform-theme-datepicker:not(.gform-legacy-datepicker) .ui-datepicker-calendar .ui-state-default:hover,
  .gform-theme-datepicker:not(.gform-legacy-datepicker) .ui-datepicker-calendar .ui-state-default:focus {
    background: none;
    border-color: #607382;
    outline: none;
  }
  
  .gform-theme-datepicker:not(.gform-legacy-datepicker) .ui-datepicker-calendar .ui-state-default:active {
    background: #f2f3f5;
    border-color: #607382;
  }
  
  .gform-theme-datepicker:not(.gform-legacy-datepicker) .ui-datepicker-calendar .ui-datepicker-current-day .ui-state-default {
    background: #607382;
    border-color: #607382;
    box-shadow: 0 2px 2px rgba(58, 58, 87, 0.0596411);
    color: #ffffff;
  }
  
  .gform-theme-datepicker:not(.gform-legacy-datepicker) .ui-datepicker-calendar .ui-datepicker-current-day .ui-state-default:hover,
  .gform-theme-datepicker:not(.gform-legacy-datepicker) .ui-datepicker-calendar .ui-datepicker-current-day .ui-state-default:focus {
    border-color: #607382;
  }
  
  .gform-theme-datepicker:not(.gform-legacy-datepicker) .ui-datepicker-calendar .ui-state-disabled {
    background: none;
  }
  
  .gform-theme-datepicker:not(.gform-legacy-datepicker) .ui-datepicker-calendar .ui-state-disabled .ui-state-default {
    align-items: center;
    background: #f2f3f5;
    border: 1px solid rgba(32, 32, 46, 0.079);
    border-radius: 100%;
    box-shadow: 0 2px 2px rgba(58, 58, 87, 0.0596411);
    color: #686e77;
    cursor: text;
    display: flex;
    height: 40px;
    justify-content: center;
    text-decoration: none;
    width: 40px;
  }
  
  html[dir="rtl"] #ui-datepicker-div.gform-theme-datepicker[style] {
    right: auto !important;
  }