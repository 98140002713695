// Custom.scss
// Option A: Include all of Bootstrap

// Include any default variable overrides here (though functions won't be available)

// Toggle global options

$enable-rounded: false;
$enable-shadows: false;
$enable-gradients: false;

$offcanvas-box-shadow: 0 1rem 3rem rgba(0, 0, 0, .175);

// Customize some defaults
$body-color: #333;
$body-bg: #fff;
//$border-radius: 0;
$primary: #1482c5;

$black: #0c0c0c;

$font-size-root:              null !default;
$font-size-base:              1.125rem !default; // Assumes the browser default, typically `16px`
$font-size-sm:                $font-size-base * .875 !default;
$font-size-lg:                $font-size-base * 1.25 !default;

$btn-font-weight: 500;

$font-family-serif: dovetail-mvb, system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

$font-family-sans-serif: "museo-sans", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$headings-font-family: dovetail-mvb, system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

$font-family-base: $font-family-sans-serif;

$font-weight-lighter:         lighter !default;
$font-weight-light:           300 !default;
$font-weight-normal:          300 !default;
$font-weight-semibold:        500 !default;
$font-weight-bold:            700 !default;
$font-weight-bolder:          bolder !default;

$spacer: 1rem !default;
$headings-margin-bottom:      $spacer * 1.25 !default;

// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

// scss-docs-start input-btn-variables
$input-btn-padding-y:         .375rem !default;
$input-btn-padding-x:         .75rem !default;
$input-btn-font-family:       $font-family-sans-serif;
$input-btn-font-size:         .75rem;
//$input-btn-line-height:       $line-height-base !default;

$input-btn-focus-width:         .25rem !default;
$input-btn-focus-color-opacity: .25 !default;
//$input-btn-focus-color:         rgba($component-active-bg, $input-btn-focus-color-opacity) !default;
$input-btn-focus-blur:          0 !default;
//$input-btn-focus-box-shadow:    0 0 $input-btn-focus-blur $input-btn-focus-width $input-btn-focus-color !default;

$input-btn-padding-y-sm:      .25rem !default;
$input-btn-padding-x-sm:      .5rem !default;
//$input-btn-font-size-sm:      $font-size-sm !default;

$input-btn-padding-y-lg:      .5rem !default;
$input-btn-padding-x-lg:      1rem !default;
//$input-btn-font-size-lg:      $font-size-lg !default;

//$input-btn-border-width:      $border-width !default;



// Pagination

// scss-docs-start pagination-variables
$pagination-padding-y:              .375rem !default;
$pagination-padding-x:              .75rem !default;
$pagination-padding-y-sm:           .25rem !default;
$pagination-padding-x-sm:           .5rem !default;
$pagination-padding-y-lg:           .75rem !default;
$pagination-padding-x-lg:           1.5rem !default;


// scss-docs-end pagination-variables

@import "../../node_modules/bootstrap/scss/bootstrap";

// Then add additional custom code here

@import "partials/labels-descriptions";
@import "partials/fields-advanced";
@import "partials/chosen";
@import "partials/datepicker";

$brand: #FFCE34;



$custom-colors: (
 "brand-yellow": $brand 
);

$theme-colors: map-merge($theme-colors, $custom-colors);



/* Gravity forms */

.gform_wrapper {
  margin-bottom: $spacer;

  /* Hidden.
  -------------------------------------------------------------- */
  .gform_validation_container, // anti-spam honeypot
  .gform_hidden {
    @extend .visually-hidden;
  }

  /* Pages.
  -------------------------------------------------------------- */
  .gf_page_steps {
    width: 100%;
    margin-bottom: 0.5rem;
    padding-bottom: 0.25rem;
    border-bottom: 1px dotted $border-color;
    font-size: $font-size-sm;

    .gf_step {
      width: auto;
      display: inline-block;
      margin: 0.5rem 2rem 0.5rem 0;
      opacity: 0.6;

      span {
        margin: 0 0.125rem;
      }

      &.gf_step_active {
        opacity: 1;
        font-weight: $font-weight-bold;
      }
    }
  }

  /* Validation.
  -------------------------------------------------------------- */
  .gfield_required {
    color: $danger;
  }

  .gform_validation_errors {
    @extend .alert;
    @extend .alert-warning;

    font-size: $font-size-sm;

    &:focus {
      outline: none;
      border: 1px solid $warning;
      box-shadow: $box-shadow-sm;
    }
  }
}

/* Confirmation.
-------------------------------------------------------------- */
.gform_confirmation_wrapper {
  @extend .alert;
  @extend .alert-success;
}

.gform-body {
  input, select, textarea {
    box-shadow:none!important;
    border:0!important;
  }
}

/*
 *Misc
 */

 .row {
  //margin:0;
 }

figure {
  margin:0;
}



.yellow {
  color:$brand!important;
 }

 .placeholder-block {
    padding:4rem;
    text-align:center;
    font-size:1.5rem;
    background: linear-gradient(180deg, #e5e5e5, #f1f1f1);
 }

 /*
 * Section Wraps
 */

 .section-grey {
  background:#f1f1f1;
 }

 .section-grey-block {
  background:#363636;
 }

 .section-white {
  background:#fff;
 }

 .section-blue {
  background:$primary;
  color:$white;
 }

 .section-brand {
  background:#FFCE34;
 }

 .section-charcoal {
  background:rgba(40, 40, 40,1);
  color:rgba($white, 1);
 }

 .section-grey-dark {
  background:#e5e5e5;
 }

 .section-minimal {
  background:#fff;
 }

 /*
 * Forms
 */

 form {
  font-family: $font-family-sans-serif;
 }

.form-control-lg, .form-select-lg {
  font-size: 1rem;
  padding:1rem 1.5rem;
}

#valuation {
  background:#e5e5e5;

  .form-floating {
    .form-control {
      
    }
    label {
      text-transform:uppercase;
      //padding-left:0;
      //padding-right:0;
    }
  }

  input {
    border:0;
    border-bottom:1px solid #ccc;
    background:#fff;
  }
  select {
    padding: 1rem 0.75rem;
    font-size: 1rem;
    text-transform: uppercase;
  }
}


  /*
 * Buttons
 */

 .btn {
    text-transform: uppercase;
   }
  
   

   .btn-primary {
    @include button-variant($primary, darken($primary, 3%));
    //padding: .875rem 2rem;
    letter-spacing:.063rem;
    color:$white;
   }


   .btn-brand {
    @include button-variant($brand, darken($brand, 3%));
    //padding: .875rem 2rem;
    letter-spacing:.063rem;
    color:$black;
   }
   
  
   .btn-xl {
    @include button-size(1rem, 1.5rem, .875em, 0);
   }

// Header

.tp-top {
  background-color: #282828;
 }

.header {
    background:rgba(255, 255, 255, 1);
}

.home {
  .header {
    z-index: 3;
    position: absolute;
    top: 45px;
    left: 0;
    right: 0;
    background:rgba(0, 0, 0, .2);
  }

  #main-nav {
    .nav-link {
      color:rgba(255, 255, 255, 1);
      //text-transform: uppercase;
      padding:.875rem 2rem;
      font-size:1rem;
      font-weight:500;
      //border:5px solid transparent;
      
      &:hover {
      color:rgba(255, 255, 255, .7)
      }
  }

  .spotlight {
      color:rgba(255, 255, 255, 1);
      padding:.875rem 2rem;
      background:transparent;
      border:5px solid $brand;
  }
  }
} 

#main-nav {

    .nav-link {
        color:rgba(0, 0, 0, 1);
        //text-transform: uppercase;
        padding:.875rem 2rem;
        font-size:1rem;
        font-weight:500;
        //border:5px solid transparent;
        
        &:hover {
        color:rgba(0, 0, 0, .7)
        }
    }

    .spotlight {
      color:rgba(0, 0, 0, 1);
      padding:.875rem 2rem;
      background:transparent;
      border:5px solid $brand;
  }

}

#offcanvas-nav {
  background: #282828;
  color:rgba(255, 255, 255, .8);

  .btn-close {
    color:rgba(255, 255, 255, .8);

    &:hover {
      color:rgba(255, 255, 255, .6);
      }
  }

    .nav-link {
      color:rgba(255, 255, 255, 1);
      text-transform: uppercase;
      padding:.875rem 2rem;
      font-size:1rem;
      font-weight:500;
      border-bottom:1px solid rgba(255, 255, 255, .05);
      
      &:hover {
      color:rgba(255, 255, 255, .6);
      }
  }

  .spotlight {
    //color:rgba(0, 0, 0, 1);
    //padding:.875rem 2rem;
    //background:transparent;
    //border:5px solid $brand;
  }

}

// WP header

.header-link {
  text-decoration:none;
  i {
    margin-left:.75rem;
  }
}

.page-banner {
    background-color: #000;
    padding: 200px 0 150px 0;
    position: relative;
    color:white;
  
    &__content {
      position: relative;
      z-index: 2;
    }
  
    &__title {
      font-weight: 300;
      font-size: 3.6rem;
      margin: 0 0 1rem 0;
      color: #fff;
    }
  
    &__bg-image {
      opacity: 1;
      background-size: cover;
      background-position: center center;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
  
    &__intro {
      font-weight: 300;
      font-size: 1.2rem;
      line-height: 1.3;
      color: #ededed;
    }
  
    &__intro p {
      margin: 0;
    }
  }

  .areoi-background__overlay {
    background: linear-gradient(45deg, rgba(0,0,0,.9), rgba(0,0,0,0))!important;
  }

    /*
 * Typography
 */
 .inline-h2 {
  display:inline;
 }

  // Cards

.card-callout {
  background:#282828;
  color:$white;

  position: sticky!important;
    top: 0;
    z-index: 1020;

  .card-body {
    padding:0 0 2rem 0;
  }

  .card-footer {
    border:0;
    padding:0;
    background:transparent;
  }
  }

  .card-img-top {
    //height: 225px;
    //width:340px;
    object-fit: cover;

    @media only screen and (min-width : 992px) {
      height: 225px;
      //width:340px;
      object-fit: cover;
    }
  }

.property-single {
  border:0;
  padding:0;
  background:rgba(54,54,54,1);
  width:100%;
  text-align: center;

  .card-header {
    background:$brand;
    color:$black;
    padding:.5rem .875rem;
    font-size:.875rem;
    border:none;
  }

  .card-img-top {
    //height: 225px;
    //width:340px;
    object-fit: cover;

    @media only screen and (min-width : 992px) {
      height: 225px;
      //width:340px;
      object-fit: cover;
    }
  }

    .card-title {

      a{
        text-decoration: none;
      }

    }

    .card-body {

      padding:2rem;

      .card-text {
          a{
            text-decoration: none;
            color:rgba(255,255,255,1);
          }
          .street {
            color:$white;
            margin-bottom:.5rem;

          }
          .town {
            color:#ccc;
          }
        }
    }

    .card-footer {
      background:#282828;
      color:$white;
      font-size: .875rem;

    }
}

.card-post {
  border:0;
  padding:0;
  background:$white;

    .card-title {

      a{
        text-decoration: none;
      }

    }

    .card-body {
      padding:2rem;
      background:#363636;
      color:$white;
      
      a{
        color:$white;
        text-decoration: none;
      }
    }

    .card-footer {
      background:#282828;
      color:$white;
      border:0;
      font-size: .875rem;

    }
}

.card-services {
  border:0;
  padding:0;
  background:transparent;

  .card-header {
    border:0;
    padding:0;
    background:transparent;
  }

  .card-body {
    padding:0 0 2rem 0;
  }

  .card-footer {
    border:0;
    padding:0;
    background:transparent;
  }
 }


  /*
 * Property Single
 */

 .property-key-features {
  font-size:1rem;
 }

 

   /*
 * Footer
 */

 #footer {
    background:#282828;
  
    .address {
      color:rgba($white, .5);
  
      .office {
        color:rgba($white, 1);
      }
    }
  
    .nav-link {
      color:rgba(255, 255, 255, .5);
      //font-weight:500;
      
      &:hover {
        color:rgba(255, 255, 255, .7)
      }
    }
}

/*--Map popups--*/
#wppf_map {
  width: 100%;
  height: 350px;
}

#wppf_map_single {
  width: 100%;
  height: 450px;
}

.wppf_marker_content {
 width: 160px;
 padding-bottom: 15px;
}
.wppf_marker_content a {
 color: black;
 font-size: 12px;
 line-height: 16px;
 text-decoration: none;
}
.wppf_pin_image { width: 100%; margin-bottom: 10px }

.wppf_sort {
  padding: 30px;
  text-align: right;
}

.wppf_view_type {
  bottom: 0;
  right: 0;
  margin: 0;
}
.wppf_view_type a {
  color: #aaa;
  border-top: 1px solid #aaa;
  border-left: 1px solid #aaa;
  border-right: 1px solid #aaa;
  padding: 5px;
  font-size: 25px;
  display: inline-block;
  margin: 0;
  line-height: 1;
}
.wppf_view_type a.active {
  color: #fff;
  background: #aaa;
}

//

/*.wp-block-navigation__responsive-container.is-menu-open .wp-block-navigation__responsive-container-content .wp-block-navigation-item,
.wp-block-navigation__responsive-container.is-menu-open .wp-block-navigation__responsive-container-content .wp-block-navigation__container,
.wp-block-navigation__responsive-container.is-menu-open .wp-block-navigation__responsive-container-content .wp-block-page-list {
    padding:0 0 2rem 0;
    border-bottom:1px solid #ccc;
    }

    .wp-block-navigation__responsive-container.is-menu-open .wp-block-navigation__responsive-container-content .wp-block-navigation__container {
        // the container
    }

    .wp-block-navigation__responsive-container.is-menu-open .wp-block-navigation__responsive-container-content .wp-block-navigation-item {
        //the li
    }

    .wp-block-navigation__responsive-container.is-menu-open .wp-block-navigation__responsive-container-content .wp-block-navigation__content {
        // the av
    }
*/

.property-list-single {
  a {
    background-size:cover;
    background-position:center;
  }

  .equaliser {
    min-height:300px;
  }
}

.owl-prev, .owl-next {
  width: 15px;
  height: 100px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: block !important;
  border:none;
}
.owl-prev { left: 2rem; }
.owl-next { right: 2rem; }
.owl-prev i, .owl-next i {
  font-size:3rem;
  color:rgba($white,1);
}

.owl-theme .owl-nav [class*=owl-]:hover {
  background:none;
  color: rgba($black,.7);
  text-decoration: none;
}

.doc-container {
  border:none;
  background:#fff;
  padding:0;
  margin:0;

  .card-header {
    border:none;
    padding:0;
    margin:0;
    background:none;
  }

  .card-body {
    border:none;
    padding:0;
    margin:0;
  }

  .card-footer {
    border:none;
    padding:0;
    margin:0;
    background:none;
  }
}

.single-property-title {
  h1 {
    margin-bottom:.75rem;
    
  }
  h2 {
    color:#999;
    margin-bottom:.75rem;
  }
}

.viewing {
}

.owl-carousel {
  //height:200px;
  overflow:hidden;

  .owl-stage {
    display:flex;
    align-items: center;
    max-height:550px;
    overflow:hidden;
    
  }
  
}

@include media-breakpoint-up(sm) {
  .owl-carousel {
    //height:40vh;
    //height:555px;
    
  }
}

@include media-breakpoint-up(lg) {
  .owl-carousel {
    //height:40vh;
  }
}

@include media-breakpoint-up(xl) {
  .owl-carousel {
    //height:50v;
  }
}

.royalSlider {
  width:100%;
  //height:466px;
  //overflow:hidden;
}

/* Theme Custom CSS start */
.wp-site-blocks {
  padding-top:0;
  padding-bottom:0;
  }
  
  .wp-site-blocks > * + * {
      margin-block-start: 0;
  }
  
  
  :where(body .is-layout-flow) > * {
      margin-block-start: 0;
      margin-block-end: 0;
  }
  
  .wp-block-navigation__responsive-container-close, .wp-block-navigation__responsive-container-open {
  padding:1rem 1.5rem;
  background:#FFCE34;
    svg {
      fill:#000;
    }
  
  }
  
  .wp-block-navigation__responsive-container.is-menu-open .wp-block-navigation__responsive-container-content .wp-block-navigation__container {
  padding:3rem;
  margin-top:46px;
      }
  
      .wp-block-navigation__responsive-container.is-menu-open .wp-block-navigation__responsive-container-content .wp-block-navigation-item {
          border-bottom:1px solid #333;
          //padding-bottom:1rem;

        a {
          font-size:1.5rem;
        }
        
        ul {

            padding:1rem 0 1rem 0!important;

          li {
            border-bottom:0;
            padding-bottom:.5rem;

            a {
              font-size:1rem;
              color:#ccc;
            }
          }
        }

      }
  
      .wp-block-navigation__responsive-container.is-menu-open .wp-block-navigation__responsive-container-content .wp-block-navigation__content {
      }
  
  
  
  .wp-block-navigation a:where(:not(.wp-element-button)):hover {
      text-decoration: none;
  }
  
  .wp-block-navigation a:where(:not(.wp-element-button)):focus,
  .wp-block-post-title a:where(:not(.wp-element-button)):focus,
  .wp-block-site-title a:where(:not(.wp-element-button)):focus,
  a:where(:not(.wp-element-button)):focus {
    text-decoration: none;
  }

  /* Nav */

ul.wp-block-navigation__container {

  li {

    a {

    }

    ul.wp-block-navigation__responsive-container.is-menu-open .wp-block-navigation__responsive-container-content .has-child .wp-block-navigation__submenu-container {

      padding:0;
      margin:0;

      li {

        border-bottom:0;

        a {

        }
      }
    }
  }

}

li.wp-block-navigation-item.wp-block-navigation-link {

}

a.wp-block-navigation-item__content {

}

ul.wp-block-navigation__submenu-container {

}
ul.wp-block-navigation-submenu {

}

li.wp-block-navigation-item.wp-block-navigation-link {

}

a.wp-block-navigation-item__content {

}

span.wp-block-navigation-item__label {

}
  
  .leaflet-popup-content-wrapper {
      padding: 1px;
      text-align: left;
      border-radius: 0!important;
  }
  
  
  /* Theme Custom CSS end */


  @media ( min-width: 1080px) {
    .wp-block-navigation.nav-primary--mobile {
      display:none;
    }
  }

  @media ( max-width: 1081px) {
    .wp-block-navigation.nav-primary--desktop {
      display:none;
    }
  }




